import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useRef, useState } from "react";

export function BackgroundImage({ image, ...props }: { image: GatsbyImageProps } & any) {

    const ref = useRef<HTMLDivElement | null>();
    const [src, setSrc] = useState<string | null>(null);

    const onload = useCallback(() => {
        if (!ref.current) { return }
        const container = ref.current;
        const img = container.querySelector("img")!

        setSrc(img.currentSrc)
    }, [ref.current])

    return <div ref={a => { ref.current = a }} {...props} style={{
        backgroundImage: src ? `url("${src}")` : "unset",
        ...props.style
    }}>
        {!src && <GatsbyImage onLoad={onload} {...image} style={{ height: "100%" }} hidden={!!src} />}
    </div>
}
