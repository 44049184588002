import { FaMusic } from "react-icons-all-files/fa/FaMusic";

import {
  graphql,
  Link,
  navigate,
  useScrollRestoration,
  useStaticQuery,
} from "gatsby";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import logo from "../images/logo.png";
import "../styles/sass/main.scss";

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { ExternalLink } from "../components/externanchor";
import { BackgroundImage } from "../components/BackgroudImage";

const getView = (showArticle: boolean) => ({
  isArticleVisible: showArticle,
  showHeader: !showArticle,
  showFooter: !showArticle,
  showMain: showArticle,
  articleActive: showArticle,
});

const hintBrowser = {
  _map: {
    "#main article": "transform, opacity",
    "#header": "opacity",
    "#footer": "opacity, transform",
    "#_bg img": "filter, transform",
  },
  apply() {
    Object.entries(this._map).forEach(([s, p]) => {
      const el = document.querySelector(s) as any;
      if (!el) {
        return;
      }
      el.style.willChange = p;
    });
  },
  remove() {
    Object.entries(this._map).forEach(([s, p]) => {
      const el = document.querySelector(s) as any;
      if (!el) {
        return;
      }
      el.style.willChange = "auto";
    });
  },
};
export const PageTransitionContext = React.createContext<any>(null);

export function ArticleLink({ to, ...rest }: any) {
  const transition = useContext(PageTransitionContext);
  return (
    <Link
      {...rest}
      to={to}
      onClick={(ev: any) => {
        ev.preventDefault();
        transition.go(() => {
          navigate(to);
        });
      }}
    />
  );
}
export function HomeLink({ ...rest }: any) {
  return <ArticleLink to="/" {...rest} />;
}

function ArticleLayout({ children, showArticle = false, location }: any) {
  const {
    site: {
      siteMetadata: { theme: defaultTheme, allowSwitchTheme },
    },
    amplify: {
      listSiteThemes: { items: themeItems },
      listFeatures: { items: features },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          theme
          allowSwitchTheme
        }
      }
      amplify {
        listFeatures {
          items {
            homeContent
            homeContentHtml
            embetYouTubeVideoURL
          }
        }
        listSiteThemes {
          items {
            name
            backgroundImageURL
            parametersJSON
            articleBackgroundImageURL
            articleBackgroundImageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            backgroundImageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);
  const overrideTheme = globalThis.window
    ? new URLSearchParams(window.location.search).get("initialTheme")
    : null;
  const { theme, themes, handleChange } = useTheme(
    themeItems.map((t) => ({ ...t, parameters: JSON.parse(t.parametersJSON) })),
    overrideTheme || defaultTheme
  );

  const [preload, setPreload] = useState(true);
  useEffect(() => {
    let timeout = setTimeout(() => {
      setPreload(false);
    });
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [view, setView] = useState(getView(showArticle));

  const transition = useRef(false);
  const handleLink = useCallback(
    (handleGo: any) => {
      hintBrowser.apply();
      transition.current = true;
      return new Promise<any>((resolve) => {
        if (showArticle) {
          setView((s) => ({
            ...s,
            articleActive: false,
          }));
          setTimeout(() => {
            handleGo();
            setTimeout(() => {
              setView((s) => ({
                ...s,
              }));
              resolve(undefined);
            });
          }, 300);
          return;
        }
        setView((s) => ({
          ...s,
          isArticleVisible: true,
        }));
        setTimeout(() => {
          resolve(null);
          handleGo();
        }, 300);
      }).finally(() => {});
    },
    [showArticle]
  );

  useEffect(() => {
    new Promise((r) => {
      if (!transition.current) {
        setView(getView(showArticle));
        return;
      }
      window.scrollTo(0, 0);
      wrapperRef.current?.scroll?.(0, 0);
      if (showArticle) {
        setView((s) => ({
          ...getView(showArticle),
          articleActive: s.articleActive,
        }));
        setTimeout(() => {
          setView((s) => ({ ...s, articleActive: true }));
          transition.current = false;
          r(null);
        }, 100);
      } else {
        setView((s) => ({
          ...s,
          showFooter: true,
          showHeader: true,
          showMain: false,
        }));
        setTimeout(() => {
          setView((s) => ({ ...s, isArticleVisible: false }));
          setTimeout(() => {
            transition.current = false;
            r(null);
          }, 100);
        }, 100);
      }
    }).finally(() => {
      hintBrowser.remove();
    });
  }, [location.pathname, showArticle]);

  // const aboutMeScrollRestoration = useScrollRestoration(`page-component-main`);

  const backgroundImage = getImage(theme.backgroundImageFile);
  const articleBackgroundImage = getImage(theme.articleBackgroundImageFile);
  const wrapperRef = useRef();

  return (
    <PageTransitionContext.Provider value={{ go: handleLink }}>
      <div
        // {...aboutMeScrollRestoration}
        id="container"
        data-theme={theme.name}
        className={
          preload
            ? "is-preload"
            : view.isArticleVisible
            ? "is-article-visible"
            : ""
        }
      >
        {allowSwitchTheme && (
          <select
            value={theme.name}
            onChange={(ev) => handleChange(ev.currentTarget.value)}
            id="theme-selector"
            style={{
              width: `${Math.max(...themes.map((t) => t.name.length)) + 1}rem`,
            }}
          >
            {themes.map((t) => (
              <option key={t.name} children={t.name} value={t.name} />
            ))}
          </select>
        )}
        {/*
      Dimension by HTML5 UP
      html5up.net | @ajlkn
      Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
  */}
        {/* Wrapper */}
        <div
          id="wrapper"
          ref={wrapperRef}
          onClick={
            showArticle
              ? (ev) => {
                  handleLink(() => {
                    navigate("/");
                  }).then(() => {});
                }
              : undefined
          }
        >
          {/* Header */}
          <header
            id="header"
            style={{ display: view.showHeader ? undefined : "none" }}
          >
            <div className="logo">
              <span className="icon">
                <FaMusic size="2em"></FaMusic>
              </span>
            </div>
            <div className="content">
              <div className="inner">
                <h1 hidden id="title">mosquitone</h1>
                <StaticImage layout="fullWidth" src="../images/logo.png" alt="mosquitone"/>
                <p className="sub" style={{margin: "0 10px"}}>emo band in tokyo jp.</p>
              </div>
            </div>
            <nav>
              <ul className="use-middle">
                <li>
                  <ArticleLink state={{ intoArticle: true }} to="/intro/">
                    Intro
                  </ArticleLink>
                </li>
                <li>
                  <ArticleLink state={{ intoArticle: true }} to="/music/">
                    Music
                  </ArticleLink>
                </li>

                <li>
                  <ArticleLink state={{ intoArticle: true }} to="/live/">
                    Live
                  </ArticleLink>
                </li>
                <li>
                  <ArticleLink state={{ intoArticle: true }} to="/contact/">
                    Contact
                  </ArticleLink>
                </li>
                <li>
                  <ArticleLink state={{ intoArticle: true }} to="/about/">
                    About
                  </ArticleLink>
                </li>
              </ul>
            </nav>
          </header>
          {/* Main */}
          <main
            id="main"
            style={{
              display: view.showMain ? undefined : "none",
              position: "relative",
            }}
          >
            <article
              onClick={(ev) => {
                ev.stopPropagation();
              }}
              className={view.articleActive ? "active" : ""}
              style={{
                display: showArticle ? undefined : "none",
                ...theme.parameters?.style?.article,
              }}
            >
              {articleBackgroundImage && (
                <BackgroundImage
                  className="background"
                  alt=""
                  image={{ image: articleBackgroundImage, alt: "" }}
                />
              )}
              {showArticle && <section className="body">{children}</section>}
            </article>
          </main>
          {/* Footer */}
          {!showArticle && features[0] && (
            <>
              <div
                id="featureHomeContent"
                className="font-english"
                dangerouslySetInnerHTML={{
                  __html: features[0].homeContentHtml,
                }}
              />
              <iframe
                id="featureEmbetYouTubeVideo"
                src={features[0].embetYouTubeVideoURL}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </>
          )}{" "}
          <footer
            id="footer"
            style={{ display: view.showFooter ? undefined : "none" }}
          >
            <p className="copyright">
              © mosquitone. Image:{" "}
              <ExternalLink href="https://unsplash.com/">Unsplash</ExternalLink>{" "}
              Design:{" "}
              <ExternalLink href="https://html5up.net">HTML5 UP</ExternalLink>.
            </p>
          </footer>
        </div>
        {/* BG */}
        {backgroundImage && (
          <BackgroundImage
            id="_bg"
            style={{ ...theme.parameters?.style?.bg }}
            image={{
              alt: "",
              objectFit: "cover",
              loading: "eager",
              image: backgroundImage,
            }}
          />
        )}{" "}
      </div>
    </PageTransitionContext.Provider>
  );
}

function useTheme(themes, initial = null) {
  const [theme, setTheme] = useState(initial);
  useEffect(() => {
    const theme = globalThis?.sessionStorage?.theme;
    if (theme) {
      setTheme(theme);
    }
  }, []);
  const themeNode = useMemo(
    () => themes.find((t) => t.name === theme) || themes[0],
    [theme]
  );
  const handleChange = useCallback((theme) => {
    sessionStorage.theme = theme;
    setTheme(theme);
  }, []);

  return { theme: themeNode, themes, handleChange };
}

export default function Layout({ pageContext, ...rest }: any) {
  const layout = pageContext.layoutOption?.layout;

  if (layout === "article") {
    return React.createElement(ArticleLayout, {
      pageContext,
      ...(pageContext.layoutOption || {}),
      ...rest,
    });
  }

  return <div>{rest.children}</div>;
}
